import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyChFOgY-u3ruaidrt5vzNmJZkkWt4NwGNw",
  authDomain: "eseditor-ac09e.firebaseapp.com",
  projectId: "eseditor-ac09e",
  storageBucket: "eseditor-ac09e.appspot.com",
  messagingSenderId: "550029840420",
  appId: "1:550029840420:web:28125a638805d7fbda48b1",
  measurementId: "G-3H3DCP9WWP"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);

// Firestoreの読み取り回数をカウントするグローバル変数
export let firestoreReadCount = 0;

// Firestoreの読み取りをトラックする関数
export const trackFirestoreRead = () => {
  firestoreReadCount++;
  console.log(`Firestore read count: ${firestoreReadCount}`);
};

// Firestoreの書き込み回数をカウントするグローバル変数
export let firestoreWriteCount = 0;

// Firestoreの書き込みをトラックする関数
export const trackFirestoreWrite = () => {
  firestoreWriteCount++;
  console.log(`Firestore write count: ${firestoreWriteCount}`);
};

// Authの操作回数をカウントするグローバル変数
export let authOperationCount = 0;

// Authの操作をトラックする関数
export const trackAuthOperation = () => {
  authOperationCount++;
  console.log(`Auth operation count: ${authOperationCount}`);
};