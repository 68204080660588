import React, { useEffect, useState, useCallback } from 'react';
import { Box, Flex, Spinner, useDisclosure, Text, useMediaQuery, useToast } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../element/Header';
import Sidebar from '../element/Sideber';
import { Edit } from '../element/Edit';
import { LoginModal } from '../element/LoginModal';
import { useAuth } from '../function/AuthProvider';
import { collection, getDocs, deleteDoc, doc, Timestamp } from 'firebase/firestore';
import { db, trackFirestoreRead } from '../fireConfig';
import { CreateESModal } from '../element/CreateESModal';

export const Editor = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentUser, logout } = useAuth();
  const [userESData, setUserESData] = useState([]);
  const [selectedES, setSelectedES] = useState(null);
  const [questions, setQuestions] = useState([]);
  const { userId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isBlurred, setIsBlurred] = useState(true);
  const { isOpen: isCreateESOpen, onOpen: onCreateESOpen, onClose: onCreateESClose } = useDisclosure();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [refreshSidebar, setRefreshSidebar] = useState(0);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const toast = useToast();

  const fetchQuestions = useCallback(async (esId) => {
    console.log('Fetching questions for ES 0:', esId);
    if (currentUser && currentUser.uid) {
      trackFirestoreRead(); // Firestoreの読み取りをトラック
      console.log('Fetching questions for ES 1:', esId);
      try {
        console.log('Fetching questions for ES 2:', esId);
        const questionsCollectionRef = collection(db, "users", currentUser.uid, "esData", esId, "questions");
        const querySnapshot = await getDocs(questionsCollectionRef);
        const questionsData = await Promise.all(querySnapshot.docs.map(async (doc) => {
          trackFirestoreRead(); // ドキュメントの読み取りをトラック
          const questionData = { id: doc.id, ...doc.data() };
          const documentsCollectionRef = collection(doc.ref, "documents");
          const documentsSnapshot = await getDocs(documentsCollectionRef);
          trackFirestoreRead(); // サブコレクションの読み取りをトラック
          questionData.documents = documentsSnapshot.docs.map(docDoc => {
            const documentData = { id: docDoc.id, ...docDoc.data() };
            if (documentData.comments) {
              documentData.comments = documentData.comments.map(comment => ({
                ...comment,
                status: comment.status !== undefined ? comment.status : false
              }));
            }
            return documentData;
          });
          return questionData;
        }));
        setQuestions(questionsData);
      } catch (error) {
        console.error("質問取得エラー:", error);
      }
    }
  }, [currentUser]);

  const fetchUserESData = useCallback(async () => {
    if (currentUser && currentUser.uid) {
      setIsLoading(true);
      try {
        trackFirestoreRead();
        const userESCollectionRef = collection(db, "users", currentUser.uid, "esData");
        const querySnapshot = await getDocs(userESCollectionRef);
        const esData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt instanceof Timestamp ? doc.data().createdAt : Timestamp.now(),
          updatedAt: doc.data().updatedAt instanceof Timestamp ? doc.data().updatedAt : Timestamp.now()
        }));
        setUserESData(esData);
        if (esData.length > 0 && !selectedES) {
          setSelectedES(esData[0]);
        }
      } catch (error) {
        console.error("データ取得エラー:", error);
        toast({
          title: "データ取得エラー",
          description: "ESデータの取得中にエラーが発生しました。",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentUser, selectedES, toast]);

  useEffect(() => {
    if (currentUser) {
      fetchUserESData();
    } else {
      setIsLoading(false);
    }
  }, [currentUser]); // fetchUserESDataを依存配列から削除

  useEffect(() => {
    if (selectedES) {
      fetchQuestions(selectedES.id);
    }
  }, [selectedES]); // fetchQuestionsを依存配列から削除

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error("ログアウトエラー:", error);
    }
  };

  const handleESSelect = async (es) => {
    setSelectedES(es);
    await fetchQuestions(es.id);
  };

  const handleESCreate = (newES) => {
    setUserESData([...userESData, newES]);
    setSelectedES(newES);
  };

  const handleDeleteES = async (esId) => {
    if (currentUser) {
      try {
        await deleteDoc(doc(db, "users", currentUser.uid, "esData", esId));
        setUserESData(userESData.filter(es => es.id !== esId));
        if (selectedES && selectedES.id === esId) {
          setSelectedES(null);
        }
        toast({
          title: "ESが削除されました",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error deleting ES: ", error);
        toast({
          title: "ES削除に失敗しました",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleStatusChange = useCallback((esId, newStatus) => {
    console.log(`Editor: Updating status for ES ${esId} to ${newStatus}`);
    setUserESData(prevData => {
      const newData = prevData.map(es => es.id === esId ? { ...es, status: newStatus } : es);
      console.log('Updated userESData:', newData);
      return newData;
    });
    if (selectedES && selectedES.id === esId) {
      setSelectedES(prev => {
        const updatedES = { ...prev, status: newStatus };
        console.log('Updated selectedES:', updatedES);
        return updatedES;
      });
    }
    setRefreshTrigger(prev => prev + 1);
  }, [selectedES]);

  const handleESUpdate = useCallback((updatedES) => {
    console.log('Updating ES:', updatedES);
    setUserESData(prevData => {
      const newData = prevData.map(es => es.id === updatedES.id ? updatedES : es);
      console.log('Updated userESData:', newData);
      return newData;
    });
    setSelectedES(updatedES);
    setRefreshTrigger(prev => prev + 1);
  }, []);

  const sidebarContent = (
    <Sidebar 
      key={refreshTrigger}
      esData={userESData} 
      onESSelect={handleESSelect}
      onCreateES={onCreateESOpen}
      onDeleteES={handleDeleteES}
      refreshTrigger={refreshTrigger}
      onStatusChange={handleStatusChange}
    />
  );

  if (isLoading) {
    return (
      <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box minHeight="100vh" width="100%" overflow="hidden">
      <Header setIsSidebarOpen={setIsSidebarOpen} sidebar={sidebarContent} />
      <Box 
        w='100%' 
        bgGradient='linear(to-r, blue.50, purple.50)' 
        minHeight="calc(100vh - 64px)"
        overflowX="hidden"
        position="relative"
      >
        {!isMobile && (
          <Box position="absolute" left="0" top="0" height="100%">
            {sidebarContent}
          </Box>
        )}
        <Box ml={isMobile ? 0 : "250px"} p={isMobile ? 2 : 4}>
          {console.log('userESData:', userESData)} {/* デバッグログ */}
          {console.log('selectedES:', selectedES)} {/* デバッグログ */}
          {selectedES ? (
            <Edit 
              esData={selectedES} 
              questions={questions} 
              setQuestions={setQuestions} 
              sidebar={sidebarContent}
              isMobile={isMobile}
              onESUpdate={handleESUpdate}
              onStatusChange={handleStatusChange}
            />
          ) : (
            <Box flex={1} display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="60vh">
              <Text fontSize="8xl">🤩</Text>
              <Text mt={2} fontSize="2xl" fontWeight="bold">ESを選択するか、新しいESを作成してください。</Text>
            </Box>
          )}
        </Box>
      </Box>
      
      <LoginModal 
        isOpen={isOpen} 
        onClose={() => {
          onClose();
          setIsBlurred(false);
        }} 
        onLoginSuccess={() => {
          setIsBlurred(false);
          fetchUserESData();
        }}
      />
      <CreateESModal
        isOpen={isCreateESOpen}
        onClose={onCreateESClose}
        onESCreate={handleESCreate}
      />
    </Box>
  );
};
