import React, { useState } from 'react';
import {
  Box,
  Flex,
  IconButton,
  useMediaQuery,
  Heading,
  Spacer,
  Text, 
  Avatar, 
  Button,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { useAuth } from '../function/AuthProvider';
import { BellIcon } from 'lucide-react';
import { LoginModal } from './LoginModal';
import { useNavigate } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';

const Header = ({ sidebar }) => {  // sidebarをプロパティとして追加
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { currentUser, logout } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error("ログアウトエラー:", error);
    }
  };

  return (
    <Box  borderBottom="1px" borderColor="gray.200" p={4} px={isMobile ? '4' : '20'} bg='white'>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          {isMobile && (
            <IconButton
              icon={<HamburgerIcon />}
              onClick={() => setIsSidebarOpen(prev => !prev)}
              mr={2}
              aria-label="サイドバーを開く"
            />
          )}
          <Heading size='lg' bgGradient="linear(to-r, blue.500, purple.500)" bgClip="text">ES DOCS</Heading>
        </Flex>
        <Spacer/>
        <Flex alignItems="center">
          {currentUser && !isMobile && (
            <Flex alignItems="center">
              <Avatar size="sm" name={currentUser.displayName || currentUser.email} />
              <Box ml={2}>
                <Text fontSize='sm' fontWeight='bold' mr={2}>{currentUser.displayName || 'ユーザー名未設定'}</Text>
                <Text fontSize='xs' mr={2}>{currentUser.email}</Text>
              </Box>
            </Flex>
          )}
          <IconButton
            aria-label="通知"
            icon={<BellIcon />}
            variant="ghost"
            size="md"
            mr={2}
          />
          <Button onClick={handleLogout} p={2} rounded="full" bg="black" color="white" size="sm" mx={2}>
            ログアウト
          </Button>
          <LoginModal isOpen={isOpen} onClose={onClose} />
        </Flex>
      </Flex>
      {isMobile && (
        <Drawer isOpen={isSidebarOpen} placement="left" onClose={() => setIsSidebarOpen(false)}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>サイドバー</DrawerHeader>
            <DrawerBody>
              {sidebar}  {/* ここでsidebarを使用 */}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </Box>
  );
};

export default Header;