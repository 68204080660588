import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { VStack, Box, Text, Heading, Button, IconButton, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Flex } from '@chakra-ui/react';
import { DeleteIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { StatusBadge } from './StatusBadge';

const statusOrder = ['未提出', '提出済み', '通過', '落選'];

const Sidebar = React.memo(({ esData, onESSelect, onCreateES, onDeleteES, refreshTrigger, onStatusChange }) => {
  console.log('Sidebar rendered. refreshTrigger:', refreshTrigger);
  console.log('Received esData:', esData);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [esToDelete, setEsToDelete] = useState(null);
  const [openGroups, setOpenGroups] = useState({
    '未提出': true,
    '提出済み': false,
    '通過': false,
    '落選': false
  });

  useEffect(() => {
    console.log('Sidebar useEffect triggered. refreshTrigger:', refreshTrigger);
    setOpenGroups(prev => ({...prev}));
  }, [refreshTrigger]);

  const sortedESData = useMemo(() => {
    console.time('sortESData');
    const sorted = [...esData].sort((a, b) => {
      if (a.status !== b.status) {
        return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
      }
      const dateA = a.deadline ? new Date(a.deadline) : new Date(0);
      const dateB = b.deadline ? new Date(b.deadline) : new Date(0);
      console.log(`Comparing dates: ${a.name} (${dateA}) vs ${b.name} (${dateB})`);
      return dateA - dateB;
    });
    console.timeEnd('sortESData');
    return sorted;
  }, [esData, refreshTrigger]);

  const groupedESData = useMemo(() => {
    console.log('Grouping sorted ES data...');
    return sortedESData.reduce((acc, es) => {
      if (!acc[es.status]) {
        acc[es.status] = [];
      }
      acc[es.status].push(es);
      return acc;
    }, {});
  }, [sortedESData]);

  const handleDeleteClick = (es) => {
    setEsToDelete(es);
    onOpen();
  };

  const confirmDelete = () => {
    if (esToDelete) {
      onDeleteES(esToDelete.id);
      onClose();
    }
  };

  const toggleGroup = (status) => {
    setOpenGroups(prev => ({
      ...prev,
      [status]: !prev[status]
    }));
  };

  const formatDate = (dateString) => {
    console.log('Formatting date:', dateString);
    if (!dateString) return '締切未設定';
    let date;
    if (dateString instanceof Object && dateString.seconds) {
      // Firestoreのタイムスタンプオブジェクトの場合
      date = new Date(dateString.seconds * 1000);
    } else {
      date = new Date(dateString);
    }
    if (isNaN(date.getTime())) return '無効な日付';
    return date.toLocaleDateString('ja-JP', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const renderESGroup = (status) => {
    console.log(`Rendering ES group for status: ${status}`);
    const esGroup = groupedESData[status];
    if (!esGroup || esGroup.length === 0) return null;
    const isBaseES = esGroup[0]?.company === "ベースES";

    return (
      <Box key={status}>
        <Button
          onClick={() => toggleGroup(status)}
          width="100%"
          bg="white"
          border="1px"
          borderColor="gray.200"
          borderRadius="2xl"
          p={3}
          justifyContent="space-between"
          _hover={{ bg: "gray.50" }}
          mb={2}
        >
          <Text>{status} ({esGroup.length})</Text>
          {openGroups[status] ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </Button>
        {openGroups[status] && esGroup.map((es) => {
          console.log(`Rendering ES item: ${es.name}, deadline: ${es.deadline}`);
          return (
            <Box 
              key={es.id} 
              p={2} 
              _hover={{ bg: 'gray.200' }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
              onClick={() => onESSelect(es)}
              cursor="pointer"
            >
              <Box>
                <Flex align="center">
                <StatusBadge
                  esId={es.id}
                  initialStatus={es.status}
                  onStatusChange={(newStatus) => {
                    console.log(`Sidebar: Status changed for ES ${es.id} to ${newStatus}`);
                    onStatusChange(es.id, newStatus);
                  }}
                  size='xs'
                />
                <Text fontSize="lg" fontWeight="bold">
                   {es.company || '企業名未設定'}
                </Text>
                </Flex>
                {!isBaseES && (
                  <>
                    <Text fontSize="sm" color="gray.600">
                      {es.name }
                </Text>
                <Text fontSize="xs" color="gray.500">
                      締切: {formatDate(es.deadline)}
                    </Text>
                  </>
                )}
              </Box>
              {!isBaseES && (
              <IconButton
                icon={<DeleteIcon />}
                size="sm"
                variant="ghost"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClick(es);
                }}
                aria-label="Delete ES"
              />
              )}
            </Box>
          );
        })}
      </Box>
    );
  };

  console.log('Sidebar render completed');
  return (
    <Box width="250px" ml={4} p={4}>
      <VStack ml={4} spacing={4} align="stretch" mt={10}>
        <Heading size="md" p={2}>ES一覧</Heading>
        
        {statusOrder.map(status => renderESGroup(status))}

        <Button
          bgGradient="linear(to-r, blue.400, purple.500)"
          color="white"
          onClick={onCreateES}
          _hover={{
            bgGradient: "linear(to-r, blue.500, purple.600)",
          }}
          _active={{
            bgGradient: "linear(to-r, blue.600, purple.700)",
          }}
        >
          新しいESを作成
          <Text ml={2} fontSize="xl">✍🏻</Text>
        </Button>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>ESの削除確認</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            本当に「{esToDelete?.name}」を削除しますか？
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={confirmDelete}>
              削除
            </Button>
            <Button variant="ghost" onClick={onClose}>キャンセル</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
});

export default Sidebar;
