import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Button,
  SimpleGrid,
  VStack,
  HStack,
  Icon,
  useColorModeValue,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { FaShoppingCart, FaInfoCircle, FaBars } from 'react-icons/fa';
import { LoginModal } from '../element/LoginModal';
import { SignupModal } from '../element/SignupModal';
import Header from '../element/Header';
import SinpleHeader from '../element/SinpleHeader';
import { useNavigate } from 'react-router-dom';

export const FirstPage = () => {
  const { isOpen: isLoginOpen, onOpen: onLoginOpen, onClose: onLoginClose } = useDisclosure();
  const { isOpen: isSignupOpen, onOpen: onSignupOpen, onClose: onSignupClose } = useDisclosure();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  
  const bgGradient = useColorModeValue(
    'linear(to-r, purple.50, blue.50)', 
    'linear(to-r, gray.900, purple.900)'
  );
  const textColor = useColorModeValue('gray.600', 'gray.300');

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
    
    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  

  return (
    <Box minH="100vh">
      {/* ヘッダー */}
      <SinpleHeader isReviewerPage={true} onClick={onSignupOpen} onLoginClick={onLoginOpen} isMobile={isMobile}/>
      

      <Box as="main" flex="1">
        {/* ヒーローセクション */}
        <Box position="relative">
          <Box 
            position="absolute" 
            inset="0" 
            bgGradient={bgGradient} 
          />
          <Container 
            maxW="container.xl" 
            py={{ base: 24, md: 32, lg: 40 }} 
            px={{ base: 4, md: 8 }}
            position="relative"
          >
            <SimpleGrid columns={{ base: 1, lg: 2 }} gap={8}>
              <VStack align="start" spacing={{ base: 4, md: 6 }} justify="center">
                <Heading
                  size={{ base: "2xl", md: "3xl" }}
                  bgGradient="linear(to-r, blue.400, purple.500)"
                  bgClip="text"
                  lineHeight="1.2"
                >
                  就活のES作成を、
                  <br />
                  もっとスマートに
                </Heading>
                <Text 
                  fontSize={{ base: 'md', md: 'lg', lg: 'xl' }} 
                  color={textColor} 
                  maxW="600px"
                >
                  ES DOCSは、就活生のためのエントリーシート管理・作成支援ツールです。
                  設問ごとの回答管理、提出期限の追跡、そして添削機能で、あなたの就活を強力にサポートします。
                </Text>
                <HStack spacing={4} flexWrap="wrap">
                  <Button 
                    bgGradient="linear(to-r, blue.400, purple.500)"
                    color="white"
                    _hover={{
                        bgGradient: "linear(to-r, blue.500, purple.600)",
                    }}
                    borderRadius="full"
                    size={{ base: "md", md: "lg" }}
                    onClick={onSignupOpen}
                    mb={{ base: 2, md: 0 }}
                  >
                    今すぐ始める
                  </Button>
                  <Button 
                    leftIcon={<Icon as={FaInfoCircle} />}
                    variant="outline" 
                    color="black"
                    borderRadius="full"
                    border="1px"
                    size={{ base: "md", md: "lg" }}
                    onClick={() => navigate('/reviewer/13hHvX0AOzdIXaov83dxe3Q4WCf2/jkhG9RQa2PnQdvtejjP0/Lgl4GwOouzs6rHSOdt07')}
                  >
                    添削ページを見る
                  </Button>
                </HStack>
              </VStack>
            </SimpleGrid>
          </Container>
        </Box>

        {/* 機能紹介セクション */}
        <Container maxW="container.xl" py={{ base: 12, md: 16 }} px={{ base: 4, md: 8 }}>
          <VStack spacing={{ base: 12, md: 16 }}>
            <VStack spacing={4} textAlign="center">
              <Heading size={{ base: "lg", md: "xl" }}>主な機能</Heading>
              <Text color={textColor} maxW="2xl" px={{ base: 4, md: 0 }}>
                ES DOCSは、就活生の皆さんの声を元に開発された
                エントリーシート作成支援ツールです
              </Text>
            </VStack>

            <SimpleGrid 
              columns={{ base: 1, md: 3 }} 
              spacing={{ base: 8, md: 10 }}
              px={{ base: 4, md: 0 }}
            >
              <VStack align="start" spacing={4}>
                <Heading size={{ base: "md", md: "md" }}>📝 設問別回答管理</Heading>
                <Text color={textColor}>
                  企業ごと、設問ごとにESの回答を簡単に管理できます。
                  過去の回答を効率的に再利用することも可能です。
                </Text>
              </VStack>
              <VStack align="start" spacing={4}>
                <Heading size={{ base: "md", md: "md" }}>⏰ 提出期限管理</Heading>
                <Text color={textColor}>
                  企業ごとの提出期限をカレンダーで管理。
                  重要な締め切りを見逃すことはありません。
                </Text>
              </VStack>
              <VStack align="start" spacing={4}>
                <Heading size={{ base: "md", md: "md" }}>✍️ 添削支援機能</Heading>
                <Text color={textColor}>
                  他のユーザーからの添削コメントで、
                  ESの質を向上させることができます。
                </Text>
              </VStack>
            </SimpleGrid>
          </VStack>
        </Container>

        {/* CTAセクション */}
        <Box bg="gray.50" py={{ base: 12, md: 16 }}>
          <Container maxW="container.xl" px={{ base: 4, md: 8 }}>
            <VStack spacing={{ base: 6, md: 8 }} textAlign="center">
              <Heading size={{ base: "lg", md: "xl" }}>今すぐ始めましょう</Heading>
              <Text color={textColor} maxW="2xl">
                無料で始められます。まずは新規登録から。
              </Text>
              <Button 
                size="lg"
                p={{ base: 4, md: 6 }}
                bgGradient="linear(to-r, blue.400, purple.500)"
                color="white"
                _hover={{
                    bgGradient: "linear(to-r, blue.500, purple.600)",
                }}
                borderRadius="full"
                onClick={onSignupOpen}
              >
                新規登録する
              </Button>
            </VStack>
          </Container>
        </Box>
      </Box>

      <Box as="footer" borderTop="1px" borderColor="gray.200" py={6}>
        <Container maxW="container.xl" px={{ base: 4, md: 8 }}>
          <Text textAlign="center" fontSize="sm" color="gray.500">
            © 2024 ES DOCS. All rights reserved.
          </Text>
        </Container>
      </Box>

      <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />
      <SignupModal isOpen={isSignupOpen} onClose={onSignupClose} onLoginOpen={onLoginOpen} />
    </Box>
  );
};
