import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  useToast
} from "@chakra-ui/react";
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../fireConfig';
import { useAuth } from '../function/AuthProvider';

export const CreateESModal = ({ isOpen, onClose, onESCreate }) => {
  const [season, setSeason] = useState('');
  const [type, setType] = useState('');
  const [deadline, setDeadline] = useState('');
  const [jobType, setJobType] = useState('');
  const [company, setCompany] = useState('');
  const { currentUser } = useAuth();
  const toast = useToast();

  const handleSubmit = async () => {
    if (!season || !type || !deadline || !jobType || !company) {
      toast({
        title: "入力エラー",
        description: "すべての項目を入力してください。",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const name = `${season}${type}`;
      const deadlineDate = new Date(deadline);

      const newES = {
        name,
        deadline: deadlineDate,
        jobType,
        company,
        createdAt: new Date(),
        status: '未提出'
      };

      const userESCollectionRef = collection(db, "users", currentUser.uid, "esData");
      const esDocRef = await addDoc(userESCollectionRef, newES);

      toast({
        title: "ESが作成されました",
        description: "新しいESがデータベースに追加されました。",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      onESCreate({ ...newES, id: esDocRef.id });
      onClose();
    } catch (error) {
      console.error("Error adding document: ", error);
      toast({
        title: "エラーが発生しました",
        description: "ESの作成中にエラーが発生しました。",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>新しいESを作成</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>季節</FormLabel>
              <Select value={season} onChange={(e) => setSeason(e.target.value)}>
                <option value="">選択してください</option>
                <option value="春">春</option>
                <option value="夏">夏</option>
                <option value="秋">秋</option>
                <option value="冬">冬</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>種類</FormLabel>
              <Select value={type} onChange={(e) => setType(e.target.value)}>
                <option value="">選択してください</option>
                <option value="本選考">本選考</option>
                <option value="インターン">インターン</option>
                <option value="選考会">選考会</option>
                <option value="その他">その他</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>企業名</FormLabel>
              <Input value={company} onChange={(e) => setCompany(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>提出期限</FormLabel>
              <Input type="datetime-local" value={deadline} onChange={(e) => setDeadline(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>職種</FormLabel>
              <Input value={jobType} onChange={(e) => setJobType(e.target.value)} />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            作成
          </Button>
          <Button variant="ghost" onClick={onClose}>キャンセル</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}