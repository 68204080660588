import React, { useState } from 'react';
import { EmailIcon, LockIcon, AtSignIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  VStack,
  Text,
  Link,
  useToast,
  Select
} from "@chakra-ui/react";
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import { setDoc, doc, addDoc, collection } from "firebase/firestore";
import { auth, db, trackAuthOperation, trackFirestoreWrite } from '../fireConfig';

export const SignupModal = ({ isOpen, onClose, onLoginOpen }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [university, setUniversity] = useState("");
  const [faculty, setFaculty] = useState("");
  const [graduationYear, setGraduationYear] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleSignup = async () => {
    if (!email || !password || !username || !university || !faculty || !graduationYear || !phoneNumber) {
      toast({
        title: "入力エラー",
        description: "すべてのフィールドを入力してください。",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      trackAuthOperation();
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await updateProfile(user, {
        displayName: username
      });

      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        displayName: username,
        university: university,
        faculty: faculty,
        graduationYear: parseInt(graduationYear),
        phoneNumber: phoneNumber,
        createdAt: new Date()
      });

      // ベースESの追加
      await addBaseES(user.uid);

      await sendEmailVerification(user);

      toast({
        title: "アカウント作成成功",
        description: "確認メールを送信しました。メールを確認してアカウントを有効化してください。",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      console.error("サインアップエラー:", error);
      toast({
        title: "アカウント作成エラー",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const addBaseES = async (userId) => {
    try {
      const baseESRef = await addDoc(collection(db, "users", userId, "esData"), {

        company: "ベースES",
        status: "未提出",
        createdAt: new Date(),
        updatedAt: new Date()
      });

      const baseQuestions = [
        { question: "自己PRを記入してください", content: "", characterLimit: 400 },
        { question: "学生時代に力を入れたことを記入してください", content: "", characterLimit: 400 }
      ];

      for (const question of baseQuestions) {
        await addDoc(collection(db, "users", userId, "esData", baseESRef.id, "questions"), {
          question: question.question,
          content: question.content,
          characterLimit: question.characterLimit,
          createdAt: new Date()
        });
      }

      console.log("ベースESが正常に追加されました");
    } catch (error) {
      console.error("ベースES追加エラー:", error);
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="2xl" fontWeight="bold">新規アカウント作成</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>メールアドレス</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <EmailIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  id="email"
                  type="email"
                  placeholder="your@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>パスワード</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <LockIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="••••••••"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button 
                    h="1.75rem" 
                    size="sm" 
                    onClick={togglePasswordVisibility}
                    bg="transparent"
                    _hover={{ bg: "transparent" }}
                  >
                    {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>ユーザー名</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <AtSignIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  id="username"
                  placeholder="ユーザー名"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </InputGroup>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>大学名</FormLabel>
              <Input value={university} onChange={(e) => setUniversity(e.target.value)} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>学部名</FormLabel>
              <Input value={faculty} onChange={(e) => setFaculty(e.target.value)} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>卒業年度</FormLabel>
              <Select 
                value={graduationYear} 
                onChange={(e) => setGraduationYear(e.target.value)}
                placeholder="卒業年度を選択"
              >
                <option value="25">25卒</option>
                <option value="26">26卒</option>
                <option value="27">27卒</option>
                <option value="28">28卒</option>
                <option value="29">29卒</option>
                <option value="30">30卒</option>
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>電話番号</FormLabel>
              <Input type="tel" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter flexDirection="column">
          <Button
            bg="black"
            color="white"
            width="full"
            onClick={handleSignup}
            isLoading={isLoading}
            loadingText="アカウント作成中..."
          >
            アカウント作成
          </Button>
          
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
