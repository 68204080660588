import React, { useState, useEffect } from 'react'
import { Badge } from "@chakra-ui/react"
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '../fireConfig'
import { useAuth } from '../function/AuthProvider'

const statusColors = {
  '未提出': 'blue.500 , purple.500',
  '提出済み': 'green.500 , blue.500',
  '通過': 'pink.500 , yellow.500',
  '落選': 'black , black'
}

const statusOrder = ['未提出', '提出済み', '通過', '落選']

export const StatusBadge = ({ size, esId, initialStatus, onStatusChange }) => {
  const [status, setStatus] = useState(initialStatus || '未提出')
  const { currentUser } = useAuth()

  useEffect(() => {
    setStatus(initialStatus || '未提出')
  }, [initialStatus])

  const handleStatusClick = async () => {
    if (currentUser && esId) {
      const currentIndex = statusOrder.indexOf(status)
      const nextIndex = (currentIndex + 1) % statusOrder.length
      const newStatus = statusOrder[nextIndex]

      try {
        const esRef = doc(db, 'users', currentUser.uid, 'esData', esId)
        await updateDoc(esRef, { status: newStatus })
        setStatus(newStatus)
        onStatusChange(newStatus)
        console.log(`StatusBadge: Status updated to ${newStatus} for ES ${esId}`)
      } catch (error) {
        console.error("ステータス更新エラー:", error)
      }
    }
  }

  return (
    <Badge
      textAlign='center'
      bgGradient={`linear(to-r, ${statusColors[status]})`}
      color="white"
      fontSize={size}
      fontWeight='bold'
      px={3}
      py={1}
      borderRadius="full"
      onClick={handleStatusClick}
      mr={2}
    >
      {status}
    </Badge>
  )
}
