import React, { useState } from 'react';
import { EmailIcon, LockIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  VStack,
  Text,
  Link,
  useToast
} from "@chakra-ui/react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../fireConfig';
import { useNavigate } from 'react-router-dom';
import { Button as ChakraButton } from "@chakra-ui/react";
import { trackAuthOperation } from '../fireConfig';

export const LoginModal = ({ isOpen, onClose, onLoginSuccess = () => {}, onSignupOpen }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      trackAuthOperation(); // Auth操作をトラック
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      onClose();
      onLoginSuccess(user);
      navigate(`/editor/${user.uid}`);
      toast({
        title: "ログイン成功",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("ログインエラー:", error);
      toast({
        title: "ログインエラー",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="2xl" fontWeight="bold">ES DOCSへようこそ</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel htmlFor="email">メールアドレス</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <EmailIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  id="email"
                  type="email"
                  placeholder="your@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">パスワード</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <LockIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="••••••••"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button 
                    h="1.75rem" 
                    size="sm" 
                    onClick={togglePasswordVisibility}
                    bg="transparent"
                    _hover={{ bg: "transparent" }}
                  >
                    {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter flexDirection="column">
          <Button
            bg="black"
            color="white"
            width="full"
            onClick={handleLogin}
            isLoading={isLoading}
            loadingText="ログイン中..."
          >
            ログイン
          </Button>
          <Text fontSize="sm" mt={4}>
            <Link color="blue.500" href="#">
              パスワードをお忘れですか？
            </Link>
          </Text>
          
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};