import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Textarea,
  VStack,
  SimpleGrid,
  Input,
  Avatar,
  useToast,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useMediaQuery,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Stack,
  Link,
} from "@chakra-ui/react"
import { ChevronDownIcon, AddIcon, HamburgerIcon, CheckIcon, CloseIcon, ChevronUpIcon, CopyIcon, CheckCircleIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons'
import { doc, updateDoc, collection, addDoc, getDocs, deleteDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { db } from '../fireConfig';
import { useAuth } from '../function/AuthProvider';
import { format } from 'date-fns';
import { trackFirestoreRead } from '../fireConfig'; // トラッキング数をインポト
import { formatTimestamp } from '../utils/dateUtils';
import { StatusBadge } from './StatusBadge';

// 設問追加モーダル
const AddQuestionModal = React.memo(({ isOpen, onClose, onAddQuestion }) => {
  const [newQuestion, setNewQuestion] = useState("");
  const [newQuestionCharCount, setNewQuestionCharCount] = useState();

  const handleSubmit = () => {
    console.log('AddQuestionModal: Submitting new question', newQuestion, newQuestionCharCount);
    onAddQuestion(newQuestion, newQuestionCharCount);
    setNewQuestion("");
    setNewQuestionCharCount("400");
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>新しい設問を追加</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>設問</FormLabel>
            <Input
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
              placeholder="あなたの強み・弱みを教えてください等"
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>文字数制限 (文字)</FormLabel>
            <NumberInput
              value={newQuestionCharCount}
              onChange={(valueString) => setNewQuestionCharCount(valueString)}
              step={50}
              placeholder="200"
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            追加
          </Button>
          <Button variant="ghost" onClick={onClose}>キャンセル</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

// ドキュメント追加モーダル
const AddDocumentModal = React.memo(({ isOpen, onClose, onCreateDocument }) => {
  const [newDocumentName, setNewDocumentName] = useState("");

  const handleSubmit = () => {
    console.log('AddDocumentModal: Submitting new document', newDocumentName);
    onCreateDocument(newDocumentName);
    setNewDocumentName("");
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>新しいドキュメントを作成</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>ドキュメト名</FormLabel>
            <Input value={newDocumentName} onChange={(e) => setNewDocumentName(e.target.value)} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            作成
          </Button>
          <Button variant="ghost" onClick={onClose}>キャンセル</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export const Edit = ({ esData, questions, setQuestions, sidebar, isMobile, onESUpdate, onStatusChange }) => {
  const formatDate = (dateString) => {
    if (!dateString) return '締切未設定';
    let date;
    if (dateString instanceof Object && dateString.seconds) {
      date = new Date(dateString.seconds * 1000);
    } else {
      date = new Date(dateString);
    }
    if (isNaN(date.getTime())) return '無効な日付';
    return date.toLocaleDateString('ja-JP', { year: 'numeric', month: 'long', day: 'numeric' });
  };
  const isBaseES = esData?.company === "ベースES";
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [documents, setDocuments] = useState([]);
  const [newDocumentName, setNewDocumentName] = useState('');
  const [newQuestion, setNewQuestion] = useState("");
  const [newQuestionCharCount, setNewQuestionCharCount] = useState("400");
  const toast = useToast()
  const [maxCharCount, setMaxCharCount] = useState(400);
  const { currentUser } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showCompletedComments, setShowCompletedComments] = useState(false);
  const [submissionDocumentId, setSubmissionDocumentId] = useState(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingJobType, setIsEditingJobType] = useState(false);
  const [isEditingDeadline, setIsEditingDeadline] = useState(false);
  const [editedName, setEditedName] = useState(esData.name);
  const [editedJobType, setEditedJobType] = useState(esData.jobType);
  const [editedDeadline, setEditedDeadline] = useState('');
  const [isEditingQuestion, setIsEditingQuestion] = useState(false);
  const [editedQuestionText, setEditedQuestionText] = useState('');
  const [isEditingDocument, setIsEditingDocument] = useState(false);
  const [editedDocumentName, setEditedDocumentName] = useState('');
  const [isEditingCompany, setIsEditingCompany] = useState(false);
  const [editedCompany, setEditedCompany] = useState(esData.company || '');
  const [isAddQuestionModalOpen, setIsAddQuestionModalOpen] = useState(false);
  const [isAddDocumentModalOpen, setIsAddDocumentModalOpen] = useState(false);
  const [isQuestionDropdownOpen, setIsQuestionDropdownOpen] = useState(false);

  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [editingDocumentId, setEditingDocumentId] = useState(null);

  const [isEditingMyPageUrl, setIsEditingMyPageUrl] = useState(false);
  const [isEditingAptitudeType, setIsEditingAptitudeType] = useState(false);
  const [isEditingAptitudeDeadline, setIsEditingAptitudeDeadline] = useState(false);

  const [editedMyPageUrl, setEditedMyPageUrl] = useState(esData.myPageUrl || '');
  const [editedMyPageId, setEditedMyPageId] = useState(esData.myPageId || '');
  const [editedAptitudeType, setEditedAptitudeType] = useState(esData.aptitudeType || '');
  const [editedAptitudeDeadline, setEditedAptitudeDeadline] = useState(esData.aptitudeDeadline ? formatDate(esData.aptitudeDeadline) : '');

  const [isEditingMyPageMemo, setIsEditingMyPageMemo] = useState(false);
  const [editedMyPageMemo, setEditedMyPageMemo] = useState(esData.myPageMemo || '');

  const [isAdditionalInfoVisible, setIsAdditionalInfoVisible] = useState(false);

  const [debouncedContent, setDebouncedContent] = useState('');

  const saveTimeoutRef = useRef(null);

  const [isEditingQuestionMemo, setIsEditingQuestionMemo] = useState(false);
  const [editedQuestionMemo, setEditedQuestionMemo] = useState('');

  const handleSave = useCallback(async (content) => {
    console.log('Saving content:', content);
    if (currentUser && selectedQuestion) {
      try {
        const questionRef = doc(db, 'users', currentUser.uid, 'esData', esData.id, 'questions', selectedQuestion.id);
        await updateDoc(questionRef, {
          content: content
        });
        toast({
          title: "回答が保存さました",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } catch (error) {
        console.error("回答の保存中にエラーが発生しました: ", error);
        toast({
          title: "回答の保存に失敗しました",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [currentUser, selectedQuestion, esData.id, db, toast]);

  const debouncedSave = useCallback((content) => {
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    saveTimeoutRef.current = setTimeout(() => {
      handleSave(content);
    }, 2000);
  }, [handleSave]);

  const handleAnswerChange = useCallback((e) => {
    if (selectedQuestion) {
      const newContent = e.target.value;
      setSelectedQuestion({
        ...selectedQuestion,
        content: newContent
      });
      debouncedSave(newContent);
    }
  }, [selectedQuestion, debouncedSave]);

  // コンポーネトのクリーンアップ時にタイマーをクリア
  useEffect(() => {
    return () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, []);

  const handleManualSave = useCallback(() => {
    if (selectedQuestion) {
      handleSave(selectedQuestion.content);
    } else {
      console.error("選択された設問がありません");
      toast({
        title: "回答の保存に失敗しました",
        description: "選択された設問がありません",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [selectedQuestion, handleSave, toast]);

  // esDataが変更されたきに実行される副用
  useEffect(() => {
    if (esData && esData.id) {
      fetchQuestions();
      setSelectedQuestion(null);
      setDocuments([]);
      setComments([]);
    }
  }, [esData]);

  // 設問を取得する関数
  const fetchQuestions = useCallback(async () => {
    if (esData && esData.id && currentUser) {
      try {
        const questionsCollectionRef = collection(db, 'users', currentUser.uid, 'esData', esData.id, 'questions');
        const querySnapshot = await getDocs(questionsCollectionRef);
        const questionsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setQuestions(questionsData);
        if (questionsData.length > 0) {
          setSelectedQuestion(questionsData[0]);
        } else {
          setSelectedQuestion(null);
          setDocuments([]);
          setComments([]);
        }
      } catch (error) {
        console.error("設問の取得中にエラーが発生しました:", error);
        setQuestions([]);
        setSelectedQuestion(null);
        setDocuments([]);
        setComments([]);
      }
    }
  }, [esData, currentUser, db]);

  useEffect(() => {
    if (selectedQuestion) {
      setComments(selectedQuestion.comments || []);
    }
  }, [selectedQuestion]);

  useEffect(() => {
    if (esData && esData.deadline) {
      setEditedDeadline(formatDate(esData.deadline));
    }
  }, [esData]);

  const handleCommentSubmit = async () => {
    if (newComment.trim() && currentUser && selectedQuestion) {
      const newCommentObj = {
        id: Date.now(),
        author: currentUser.displayName || currentUser.email,
        content: newComment,
        timestamp: new Date().toLocaleString('ja-JP', { hour: '2-digit', minute: '2-digit' }) + " 今日",
        status: false, // 初期状態は未完了
      };
      const updatedComments = [...comments, newCommentObj];
      setComments(updatedComments);
      setNewComment("");

      try {
        const questionRef = doc(db, 'users', currentUser.uid, 'esData', esData.id, 'questions', selectedQuestion.id);
        await updateDoc(questionRef, {
          comments: updatedComments
        });
        setSelectedQuestion({
          ...selectedQuestion,
          comments: updatedComments
        });
        toast({
          title: "メントが追加されま",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error adding comment: ", error);
        toast({
          title: "コメントの追加に失敗しました",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const handleCommentStatusChange = async (commentId, newStatus) => {
    if (currentUser && selectedQuestion) {
      try {
        const updatedComments = comments.map(comment =>
          comment.id === commentId ? { ...comment, status: newStatus } : comment
        );

        const questionRef = doc(db, 'users', currentUser.uid, 'esData', esData.id, 'questions', selectedQuestion.id);
        await updateDoc(questionRef, {
          comments: updatedComments
        });

        setComments(updatedComments);
        setSelectedQuestion({
          ...selectedQuestion,
          comments: updatedComments
        });

        toast({
          title: `コメントが${newStatus ? '完了' : '未完了'}に更新されました`,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error updating comment status: ", error);
        toast({
          title: "コメントのステース更新に失敗しました",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const handleAddQuestion = async (questionText) => {
    if (currentUser && esData) {
      try {
        const newQuestionRef = await addDoc(collection(db, 'users', currentUser.uid, 'esData', esData.id, 'questions'), {
          question: questionText,
          content: '',
          characterLimit: maxCharCount,
          createdAt: serverTimestamp(),
          comments: []
        });
        const newQuestion = {
          id: newQuestionRef.id,
          question: questionText,
          content: '',
          characterLimit: maxCharCount,
          createdAt: new Date(),
          comments: []
        };
        setQuestions([...questions, newQuestion]);
        handleQuestionSelect(newQuestion);
        toast({
          title: "設問が追加されました",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error adding question:", error);
        toast({
          title: "設問の追加に失敗しました",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleCreateDocument = async (documentName) => {
    console.log('handleCreateDocument called with:', documentName);
    if (currentUser && esData && selectedQuestion) {
      try {
        console.log('Creating document in Firestore');
        const docRef = await addDoc(collection(db, 'users', currentUser.uid, 'esData', esData.id, 'questions', selectedQuestion.id, 'documents'), {
          name: documentName,
          content: '',
          createdAt: serverTimestamp()
        });
        console.log('Document created successfully with ID:', docRef.id);

        // 新しいドキュメントを documents 配列に追加
        const newDocument = {
          id: docRef.id,
          name: documentName,
          content: ''
        };
        setDocuments([...documents, newDocument]);

        toast({
          title: "ドキュメントれました",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error creating document:', error);
        toast({
          title: "ドキュメントの作成に失敗しました",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      console.error('User, ES data, or selected question is missing');
    }
  };

  const handleDocumentSelect = useCallback((doc) => {
    console.log('Selecting document:', doc);
    setSelectedQuestion(doc);
  }, []);

  // 設問を選択する関数を更新
  const handleQuestionSelect = useCallback((question) => {
    setSelectedQuestion(question);
    setComments(question.comments || []);
    setDocuments(question.documents || []);
    setIsQuestionDropdownOpen(false);
  }, []);

  useEffect(() => {
    if (esData && esData.id) {
      fetchQuestions();
    }
  }, [esData]);

  useEffect(() => {
    if (questions.length > 0 && !selectedQuestion) {
      handleQuestionSelect(questions[0]);
    }
  }, [questions, selectedQuestion, handleQuestionSelect]);

  const toggleCompletedComments = () => {
    setShowCompletedComments(!showCompletedComments);
  };

  const renderComments = () => {
    const uncompletedComments = comments.filter(comment => !comment.status);
    const completedComments = comments.filter(comment => comment.status);

    return (
      <VStack spacing={2} align="stretch" maxHeight="800px" overflowY="auto" >
        {uncompletedComments.map(renderComment)}
        {completedComments.length > 0 && (
          <Button
            onClick={toggleCompletedComments}
            width="100%"
            bg="white"
            border="1px"
            borderColor="gray.200"
            borderRadius="2xl"
            p={3}
            justifyContent="space-between"
            _hover={{ bg: "gray.50" }}
          >
            <Text>完了したコメント ({completedComments.length})</Text>
            {showCompletedComments ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </Button>
        )}
        {showCompletedComments && completedComments.map(renderComment)}
      </VStack>
    );
  };

  const renderComment = (comment) => (
    <Box key={comment.id} bg="white" rounded="2xl" borderWidth="1px" borderColor="gray.200">
      <Box p={3}>
        <Flex align="center" mb={2}>
          <Avatar size="sm" name={comment.author} src={`https://api.dicebear.com/6.x/initials/svg?seed=${comment.author}`} mr={2} />
          <Text fontWeight="bold">{comment.author}</Text>
          <Text fontSize="xs" color="gray.500" ml={2}>{comment.timestamp}</Text>
        </Flex>
        <Text fontSize="sm">{comment.content}</Text>
      </Box>
      <Button
        width="100%"
        onClick={() => handleCommentStatusChange(comment.id, !comment.status)}
        bg="white"
        borderTop="1px"
        borderColor="gray.200"
        borderBottomRadius="2xl"
        _hover={{ bg: "gray.50" }}
      >
        {comment.status ? "未完了に戻す" : "完了にする"}
        {comment.status ? <CloseIcon ml={2} /> : <CheckIcon ml={2} />}
      </Button>
    </Box>
  );

  const handleCopyReviewerLink = () => {
    console.log('Current user:', currentUser);
    console.log('ES data:', esData);
    console.log('Selected question:', selectedQuestion);

    if (!currentUser || !esData || !selectedQuestion) {
      console.error('Required data is missing');
      console.log('Current user:', currentUser);
      console.log('ES data:', esData);
      console.log('Selected question:', selectedQuestion);
      toast({
        title: "ラー",
        description: "ドキュメントを選択してください。",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const reviewerLink = `${window.location.origin}/reviewer/${currentUser.uid}/${esData.id}/${selectedQuestion.id}`;
    navigator.clipboard.writeText(reviewerLink)
      .then(() => {
        toast({
          title: "URLをコピーました",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error('Failed to copy: ', error);
        toast({
          title: "コピーに失敗しました",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleStatusChange = async (newStatus) => {
    try {
      const esRef = doc(db, 'users', currentUser.uid, 'esData', esData.id);
      await updateDoc(esRef, { status: newStatus });

      // ローカルの状態を更新
      const updatedES = { ...esData, status: newStatus };
      onESUpdate(updatedES);

      toast({
        title: "ステータスが更新されました",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating status: ", error);
      toast({
        title: "ステータスの更新に失敗しました",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSubmissionButtonClick = async () => {
    if (selectedQuestion && selectedQuestion.id === submissionDocumentId) {
      toast({
        title: "注意",
        description: "このドキュメントは既に提用として設定されています。",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (selectedQuestion) {
      try {
        const questionRef = doc(db, `users/${currentUser.uid}/esData/${esData.id}/questions/${selectedQuestion.id}`);
        await updateDoc(questionRef, {
          submissionDocumentId: selectedQuestion.id
        });
        setSubmissionDocumentId(selectedQuestion.id);
        toast({
          title: "更新成功",
          description: "提出用ドキュメントが更新されました。",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error updating submission document: ", error);
        toast({
          title: "エラー",
          description: "提出用ドキュメントの更新に失敗しました。",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "注意",
        description: "提出したいドキュメントを選択してから押下してください。",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUpdateES = async (field, value) => {
    if (currentUser && esData) {
      try {
        const esDocRef = doc(db, 'users', currentUser.uid, 'esData', esData.id);
        let updateData = { [field]: value };

        if (field === 'deadline') {
          updateData[field] = new Date(value);
        }

        await updateDoc(esDocRef, updateData);
        const updatedES = { ...esData, [field]: field === 'deadline' ? new Date(value) : value };
        onESUpdate(updatedES);
        toast({
          title: "更新されました",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error updating ES: ", error);
        toast({
          title: "新に失敗しした",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const handleDeleteQuestion = async (questionId) => {
    if (currentUser && esData) {
      try {
        await deleteDoc(doc(db, 'users', currentUser.uid, 'esData', esData.id, 'questions', questionId));
        setQuestions(questions.filter(q => q.id !== questionId));
        if (selectedQuestion && selectedQuestion.id === questionId) {
          setSelectedQuestion(null);
        }
        toast({
          title: "設問が削除されました",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error deleting question: ", error);
        toast({
          title: "設問の削除に失敗しました",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const handleUpdateQuestion = async (questionId, newQuestionText, newMemo) => {
    if (currentUser && esData) {
      try {
        const questionRef = doc(db, 'users', currentUser.uid, 'esData', esData.id, 'questions', questionId);
        const updateData = { question: newQuestionText };
        if (newMemo !== undefined) {
          updateData.memo = newMemo;
        }
        await updateDoc(questionRef, updateData);
        setQuestions(questions.map(q => q.id === questionId ? { ...q, question: newQuestionText, memo: newMemo } : q));
        if (selectedQuestion && selectedQuestion.id === questionId) {
          setSelectedQuestion(prev => ({ ...prev, question: newQuestionText, memo: newMemo }));
        }
        toast({
          title: "設問が更新されました",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error updating question: ", error);
        toast({
          title: "設問の更新に失敗しました",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const handleEditQuestion = (question) => {
    setEditingQuestionId(question.id);
    setEditedQuestionText(question.question);
  };

  const handleSaveEditedQuestion = async () => {
    if (editingQuestionId) {
      await handleUpdateQuestion(editingQuestionId, editedQuestionText);
      setEditingQuestionId(null);
    }
  };

  const toggleAdditionalInfo = () => {
    setIsAdditionalInfoVisible(!isAdditionalInfoVisible);
  };

  console.log('Rendering Edit component');
  console.log('Selected question:', selectedQuestion);
  console.log('Documents:', documents);

  return (
    <Box w="100%" h="100vh" display="flex" flexDirection="column">


      <Flex maxW="100%" flex={1} mt={4} gap={6} direction={isMobile ? "column" : "row"} >
        <VStack flex={1}>
          <Box  flex={1} w="100%">



            <Box bg="linear-gradient(to right, blue.500, purple.500)" p={isMobile ? 2 : 4} rounded="lg">
              <Flex align="center" mb={4} justifyContent="space-between">
                <Box >
                  <Flex justifyContent="space-between" direction={isMobile ? "column" : "row"}>
                    <Box>
                      <Flex align="center">
                        <StatusBadge
                          esId={esData.id}
                          initialStatus={esData.status}
                          onStatusChange={(newStatus) => {
                            console.log(`Edit: Status changed for ES ${esData.id} to ${newStatus}`);
                            onStatusChange(esData.id, newStatus);
                          }}
                          size='md'
                        />
                        {isEditingCompany ? (
                          <Flex align="center">
                            <Input
                              value={editedCompany}
                              onChange={(e) => setEditedCompany(e.target.value)}
                              size="lg"
                              fontWeight="bold"
                              mr={2}
                            />
                            <IconButton
                              icon={<CheckIcon />}
                              onClick={() => {
                                handleUpdateES('company', editedCompany);
                                setIsEditingCompany(false);
                              }}
                              aria-label="Save company name"
                            />
                            <IconButton
                              icon={<CloseIcon />}
                              onClick={() => setIsEditingCompany(false)}
                              aria-label="Cancel editing company name"
                            />
                          </Flex>
                        ) : (
                          <Flex align="center">
                            <Text fontSize="2xl" fontWeight="bold" mr={2}>{esData.company || '企業名未設定'}</Text>
                            {!isBaseES && <IconButton
                              icon={<EditIcon />}
                              size="sm"
                              variant="ghost"
                              onClick={() => setIsEditingCompany(true)}
                              aria-label="Edit company name"
                            />}
                          </Flex>
                        )}
                      </Flex>
                      {!isBaseES && (
                        <Flex direction={isMobile ? "column" : "row"}>
                          {isEditingName ? (
                          <Flex align="center" ml={4}>
                            <Input
                              value={editedName}
                              onChange={(e) => setEditedName(e.target.value)}
                              size="md"
                              mr={2}
                            />
                            <IconButton
                              icon={<CheckIcon />}
                              onClick={() => {
                                handleUpdateES('name', editedName);
                                setIsEditingName(false);
                              }}
                              aria-label="Save ES name"
                            />
                            <IconButton
                              icon={<CloseIcon />}
                              onClick={() => setIsEditingName(false)}
                              aria-label="Cancel editing ES name"
                            />
                          </Flex>
                        ) : (
                          <Flex align="center" ml={4}>
                            <Text fontSize="lg" color="gray.600" mr={2}>{esData.name}</Text>
                            <IconButton
                              icon={<EditIcon />}
                              size="sm"
                              variant="ghost"
                              onClick={() => setIsEditingName(true)}
                              aria-label="Edit ES name"
                            />
                          </Flex>
                        )}

                        {isEditingJobType ? (
                          <Flex align="center" ml={4}>
                            <Input
                              value={editedJobType}
                              onChange={(e) => setEditedJobType(e.target.value)}
                              size="sm"
                              width="200px"
                              mr={2}
                            />
                            <IconButton
                              icon={<CheckIcon />}
                              size="sm"
                              variant="ghost"
                              onClick={() => {
                                handleUpdateES('jobType', editedJobType);
                                setIsEditingJobType(false);
                              }}
                              aria-label="Save job type"
                            />
                          </Flex>
                        ) : (
                          <Flex align="center" ml={4}>
                            <Text fontWeight="semibold" mr={2}>{esData.jobType}</Text>
                            <IconButton
                              icon={<EditIcon />}
                              size="sm"
                              variant="ghost"
                              onClick={() => setIsEditingJobType(true)}
                              aria-label="Edit job type"
                            />
                          </Flex>
                        )}
                        {!isBaseES && (
                  <Flex direction={isMobile ? "column" : "row"}>
                  <Text fontSize="sm" mr={2}>⏳提出期限</Text>
                  {isEditingDeadline ? (
                    <Flex>
                      <Input
                        type="datetime-local"
                        value={editedDeadline}
                        onChange={(e) => setEditedDeadline(e.target.value)}
                        size="sm"
                        width="200px"
                        mr={2}
                      />
                      <IconButton
                        icon={<CheckIcon />}
                        size="sm"
                        variant="ghost"
                        onClick={() => {
                          handleUpdateES('deadline', editedDeadline);
                          setIsEditingDeadline(false);
                        }}
                        aria-label="Save deadline"
                      />
                    </Flex>
                  ) : (
                    <Flex>
                      <Text fontWeight="semibold" mr={2}>{formatDate(esData.deadline)}</Text>
                      <IconButton
                        icon={<EditIcon />}
                        size="sm"
                        variant="ghost"
                        onClick={() => setIsEditingDeadline(true)}
                        aria-label="Edit deadline"
                      />
                        </Flex>
                      )} 
                      </Flex>
                    )}
                      
                      </Flex>
                      )}
                      
                    </Box>
                    
                  </Flex>
                </Box>
                
              </Flex>
            </Box>


            <VStack spacing={6} align="stretch">
              <Box bg="white" p={4} rounded="2xl" borderWidth="1px" borderColor="gray.200">
                {!isBaseES && (
                <SimpleGrid bg="white" columns={[1, null, 3]} spacing={4}>
                  <Box bg="white" rounded="lg">
                    <Text fontSize="sm" color="gray.500">🔗マイページURL</Text>
                    {isEditingMyPageUrl ? (
                      <Flex align="center">
                        <Input
                          value={editedMyPageUrl}
                          onChange={(e) => setEditedMyPageUrl(e.target.value)}
                          size="sm"
                          width="200px"
                          mr={2}
                        />
                        <IconButton
                          icon={<CheckIcon />}
                          size="sm"
                          variant="ghost"
                          onClick={() => {
                            handleUpdateES('myPageUrl', editedMyPageUrl);
                            setIsEditingMyPageUrl(false);
                          }}
                          aria-label="Save my page URL"
                        />
                      </Flex>
                    ) : (
                      <Flex align="center">
                        <Link noOfLines={1} href={esData.myPageUrl || '#'} isExternal fontWeight="semibold" mr={2}>
                          {esData.myPageUrl || '未設定'}
                        </Link>
                        <IconButton
                          icon={<EditIcon />}
                          size="sm"
                          variant="ghost"
                          onClick={() => setIsEditingMyPageUrl(true)}
                          aria-label="Edit my page URL"
                        />
                      </Flex>
                    )}
                  </Box>
                  <Box bg="white" rounded="lg">
                    <Text fontSize="sm" color="gray.500">📊適性検査タイプ</Text>
                    {isEditingAptitudeType ? (
                      <Flex align="center">
                        <Input
                          value={editedAptitudeType}
                          onChange={(e) => setEditedAptitudeType(e.target.value)}
                          size="sm"
                          width="200px"
                          mr={2}
                        />
                        <IconButton
                          icon={<CheckIcon />}
                          size="sm"
                          variant="ghost"
                          onClick={() => {
                            handleUpdateES('aptitudeType', editedAptitudeType);
                            setIsEditingAptitudeType(false);
                          }}
                          aria-label="Save aptitude type"
                        />
                      </Flex>
                    ) : (
                      <Flex align="center">
                        <Text fontWeight="semibold" mr={2}>{esData.aptitudeType || '未設定'}</Text>
                        <IconButton
                          icon={<EditIcon />}
                          size="sm"
                          variant="ghost"
                          onClick={() => setIsEditingAptitudeType(true)}
                          aria-label="Edit aptitude type"
                        />
                      </Flex>
                    )}
                  </Box>
                  <Box bg="white" rounded="lg">
                    <Text fontSize="sm" color="gray.500">⏳適性検査締切</Text>
                    {isEditingAptitudeDeadline ? (
                      <Flex align="center">
                        <Input
                          type="datetime-local"
                          value={editedAptitudeDeadline}
                          onChange={(e) => setEditedAptitudeDeadline(e.target.value)}
                          size="sm"
                          width="200px"
                          mr={2}
                        />
                        <IconButton
                          icon={<CheckIcon />}
                          size="sm"
                          variant="ghost"
                          onClick={() => {
                            handleUpdateES('aptitudeDeadline', editedAptitudeDeadline);
                            setIsEditingAptitudeDeadline(false);
                          }}
                          aria-label="Save aptitude deadline"
                        />
                      </Flex>
                    ) : (
                      <Flex align="center">
                        <Text fontWeight="semibold" mr={2}>{formatDate(esData.aptitudeDeadline)}</Text>
                        <IconButton
                          icon={<EditIcon />}
                          size="sm"
                          variant="ghost"
                          onClick={() => setIsEditingAptitudeDeadline(true)}
                          aria-label="Edit aptitude deadline"
                        />
                      </Flex>
                    )}
                  </Box>
                </SimpleGrid>
                )}
                

                

                <Box mt='3'>
                  <Heading size="sm" mb={2}>❓設問</Heading>
                  <Box position="relative" w="100%">
                    <Box
                      w="100%"
                      textAlign="left"
                      bg="white"
                      p={2}
                      borderWidth="1px"
                      borderRadius="md"
                      cursor="pointer"
                      onClick={() => setIsQuestionDropdownOpen(!isQuestionDropdownOpen)}
                    >
                      <Text>
                        {selectedQuestion ? selectedQuestion.question : (questions.length > 0 ? "設問を選択" : "設問を作成")}
                      </Text>
                    </Box>
                    {isQuestionDropdownOpen && (
                      <Box
                        position="absolute"
                        zIndex="1"
                        bg="white"
                        borderWidth="1px"
                        borderRadius="md"
                        boxShadow="md"
                        maxW="900px"
                        w="100%"
                        maxH="300px"
                        overflowY="auto"
                      >
                        {questions && questions.length > 0 && (
                          questions.map((q) => (
                            <Flex
                              key={q.id}
                              p={2}
                              cursor="pointer"
                              _hover={{ bg: "gray.100" }}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              {editingQuestionId === q.id ? (
                                <Flex flex={1} mr={2}>
                                  <Input
                                    value={editedQuestionText}
                                    onChange={(e) => setEditedQuestionText(e.target.value)}
                                    onKeyPress={(e) => {
                                      if (e.key === 'Enter') {
                                        handleSaveEditedQuestion();
                                      }
                                    }}
                                  />
                                  <IconButton
                                    icon={<CheckIcon />}
                                    size="sm"
                                    variant="ghost"
                                    onClick={handleSaveEditedQuestion}
                                    aria-label="Save edited question"
                                    ml={1}
                                  />
                                  <IconButton
                                    icon={<CloseIcon />}
                                    size="sm"
                                    variant="ghost"
                                    onClick={() => setEditingQuestionId(null)}
                                    aria-label="Cancel editing question"
                                    ml={1}
                                  />
                                </Flex>
                              ) : (
                                <>
                                  <Text noOfLines={2} onClick={() => handleQuestionSelect(q)} flex={1}>
                                    {q.question}
                                  </Text>
                                  <Box>
                                    <IconButton
                                      icon={<EditIcon />}
                                      size="sm"
                                      variant="ghost"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleEditQuestion(q);
                                      }}
                                      aria-label="Edit question"
                                      mr={1}
                                    />
                                    <IconButton
                                      icon={<DeleteIcon />}
                                      size="sm"
                                      variant="ghost"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteQuestion(q.id);
                                      }}
                                      aria-label="Delete question"
                                    />
                                  </Box>
                                </>
                              )}
                            </Flex>
                          ))
                        )}
                        <Box
                          p={2}
                          cursor="pointer"
                          _hover={{ bg: "gray.100" }}
                          onClick={() => {
                            setIsAddQuestionModalOpen(true);
                            setIsQuestionDropdownOpen(false);
                          }}
                        >
                          <AddIcon mr={2} />
                          新しい設問を追加
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {isAdditionalInfoVisible && (
                  <>
                    <Box bg="white" rounded="lg" mt={4} w="100%">
                      <Text fontSize="sm" color="gray.500">📝メモ</Text>
                      {isEditingQuestionMemo ? (
                        <Flex align="center" w="100%" >
                          <Textarea
                            value={editedQuestionMemo}
                            onChange={(e) => setEditedQuestionMemo(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === '8' && e.metaKey && e.shiftKey) {
                                e.preventDefault();
                                const cursorPosition = e.target.selectionStart;
                                const textBeforeCursor = e.target.value.substring(0, cursorPosition);
                                const textAfterCursor = e.target.value.substring(cursorPosition);
                                setEditedQuestionMemo(textBeforeCursor + '• ' + textAfterCursor);
                                // カーソル位置を更新
                                setTimeout(() => {
                                  e.target.selectionStart = e.target.selectionEnd = cursorPosition + 2;
                                }, 0);
                              }
                            }}
                            size="sm"
                            width="100%"
                            mr={2}
                            rows={5}
                          />
                          <IconButton
                            icon={<CheckIcon />}
                            size="sm"
                            variant="ghost"
                            onClick={() => {
                              handleUpdateQuestion(selectedQuestion.id, selectedQuestion.question, editedQuestionMemo);
                              setIsEditingQuestionMemo(false);
                            }}
                            aria-label="Save memo"
                          />
                        </Flex>
                      ) : (
                        <Flex align="center" p={3} w="100%" borderWidth="1px" borderColor="gray.200" rounded="lg">
                          <Text mr={2} whiteSpace="pre-wrap" w="100%">{selectedQuestion?.memo || '未設定'}</Text>
                          <IconButton
                            icon={<EditIcon />}
                            size="sm"
                            variant="ghost"
                            onClick={() => {
                              setEditedQuestionMemo(selectedQuestion?.memo || '');
                              setIsEditingQuestionMemo(true);
                            }}
                            aria-label="Edit memo"
                          />
                        </Flex>
                      )}
                    </Box>
                  </>
                )}
                <Box mt={1}>
                  <Button
                    onClick={toggleAdditionalInfo}
                    width="100%"
                    height="30px"  // 縦幅を小さくする
                    bg="white"
                    justifyContent="center"
                    _hover={{ bg: "gray.50" }}
                  >
                    <Text>{isAdditionalInfoVisible ? '閉じる' : 'メモ表示'}</Text>
                    {isAdditionalInfoVisible ? <ChevronUpIcon boxSize={6} /> : <ChevronDownIcon boxSize={6} />}
                  </Button>
                </Box>
                </Box>
              </Box>

              {selectedQuestion ? (
                <Box bg="white" p={isMobile ? 2 : 4} rounded="2xl" borderWidth="1px" borderColor="gray.200">
                  <Textarea
                    value={selectedQuestion.content}
                    onChange={handleAnswerChange}
                    rows={10}
                    borderWidth={2}
                    borderColor="gray.200"
                    rounded="lg"
                    _focus={{ ring: 2, ringColor: "blue.500", borderColor: "transparent" }}
                    placeholder="ここに回答を入力してください..."
                    style={{ wordBreak: 'break-all' }}
                  />
                  <Flex mt={2}>
                    <Text ml={2} fontSize="lg" fontWeight='semibold' color={selectedQuestion.content.length > maxCharCount ? 'red.500' : 'gray.500'}>
                      {selectedQuestion.content.length} / {maxCharCount} 文字
                    </Text>
                    <Spacer />
                    {selectedQuestion && (
                      <Button
                        leftIcon={<CopyIcon />}
                        ml={2}
                        bg="white"
                        color="gray.500"
                        _hover={{ bg: "gray.100" }}
                        onClick={handleCopyReviewerLink}
                        size="sm"
                      >
                        添削用URLをコピー
                      </Button>
                    )}
                    <Button
                      aria-label="クリップボードにコピー"
                      leftIcon={<CopyIcon />}
                      onClick={() => {
                        navigator.clipboard.writeText(selectedQuestion.content);
                        toast({
                          title: "クリップボードにコピーされました",
                          status: "success",
                          duration: 2000,
                          isClosable: true,
                        });
                      }}
                      bg="white"
                      color="gray.500"
                      _hover={{ bg: "gray.100" }}
                      ml={2}
                      size="sm"
                    >
                      コピー
                    </Button>
                  </Flex>
                  <Box mt={4}>
                    <Button
                      w="full"
                      bgGradient="linear(to-r, blue.400, purple.500)"
                      color="white"
                      fontSize="lg"
                      onClick={handleManualSave}
                      _hover={{
                        bgGradient: "linear(to-r, blue.500, purple.600)",
                      }}
                      _active={{
                        bgGradient: "linear(to-r, blue.600, purple.700)",
                      }}
                    >
                      🎉　保存する　🎉
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box textAlign="center" p={4} bg="white" borderRadius="2xl" borderWidth="1px" borderColor="gray.200">
                  <Text fontSize="md" fontWeight="bold">🖋️設問を作成してください</Text>
                </Box>
              )}
            </VStack>
          </Box>


        </VStack>

        {/* コメントセクション */}
        <Box w={isMobile ? "100%" : "350px"} flexShrink={0} overflowY="auto">
          <VStack spacing={4} align="stretch">
            {/* 広告 */}
            <a href="https://px.a8.net/svt/ejp?a8mat=3ZHQ9I+82JKRE+5JLA+5ZMCH" rel="nofollow" >
              <img border="0" width="300" height="250" alt="" src="https://www25.a8.net/svt/bgt?aid=241012278488&wid=002&eno=01&mid=s00000025867001006000&mc=1"></img>
              <img border="0" width="1" height="1" src="https://www16.a8.net/0.gif?a8mat=3ZHQ9I+82JKRE+5JLA+5ZMCH" alt=""></img>
            </a>
            <Box bg='white' p='3' rounded="2xl" borderWidth="1px" borderColor="gray.200">
              <Flex align="center" mb={2}>
                <Text fontWeight="bold">コメント</Text>
              </Flex>
              <Input
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="@を使用してメントまたはユーザーを追加"
                mb={2}
              />
              <Flex justify="flex-end">
                <Button size='sm' rounded="full" onClick={handleCommentSubmit} bg='black' textColor='white' variant='solid'>
                  送信
                </Button>
              </Flex>
            </Box>

            {/* コメント一覧 */}
            <Box maxHeight="calc(100vh - 300px)" overflowY="auto">
              {renderComments()}
            </Box>
          </VStack>
        </Box>
      </Flex>

      <AddQuestionModal
        isOpen={isAddQuestionModalOpen}
        onClose={() => setIsAddQuestionModalOpen(false)}
        onAddQuestion={handleAddQuestion}
      />
    </Box>
  )
}