import React from 'react';
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Editor } from "./pages/Editor";
import { FirstPage } from "./pages/FirstPage";
import { AuthProvider } from './function/AuthProvider';
import { ReviewerPage } from './pages/ReviewerPage';

const queryClient = new QueryClient();

const App = () => (
  <ChakraProvider>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<FirstPage />} />
            <Route path="/editor/:userId" element={<Editor />} />
            <Route path="/reviewer/:userId/:esId/:questionId" element={<ReviewerPage />} />
          </Routes>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  </ChakraProvider>
);

export default App;