import React, { useState, useEffect } from 'react';
import { Box, VStack, Heading, Text, Textarea, Button, useToast, SimpleGrid, Flex, Avatar, Icon, Container } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../fireConfig';
import SinpleHeader from '../element/SinpleHeader';

export const ReviewerPage = () => {
    const { userId, esId, questionId } = useParams();
    const [esData, setEsData] = useState(null);
    const [questionData, setQuestionData] = useState(null);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [showCompletedComments, setShowCompletedComments] = useState(false);
    const toast = useToast();
    const isMobile = window.innerWidth < 600;

    useEffect(() => {
        const fetchData = async () => {
            const esDocRef = doc(db, `users/${userId}/esData/${esId}`);
            const esDocSnap = await getDoc(esDocRef);
            if (esDocSnap.exists()) {
                setEsData(esDocSnap.data());
            }

            const questionDocRef = doc(db, `users/${userId}/esData/${esId}/questions/${questionId}`);
            const questionDocSnap = await getDoc(questionDocRef);
            if (questionDocSnap.exists()) {
                setQuestionData(questionDocSnap.data());
            }

        };

        fetchData();
    }, [userId, esId, questionId]);

    const handleCommentSubmit = async () => {
        if (newComment.trim()) {
            const commentObj = {
                content: newComment,
                timestamp: new Date(),
                author: 'Anonymous',
                status: false // 新しいコメントは未完了状態で追加
            };

            try {
                const documentRef = doc(db, `users/${userId}/esData/${esId}/questions/${questionId}`);
                await updateDoc(documentRef, {
                    comments: arrayUnion(commentObj)
                });

                setComments([...comments, commentObj]);
                setNewComment('');
                toast({
                    title: "コメントが追加されました",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } catch (error) {
                console.error("Error adding comment: ", error);
                toast({
                    title: "コメントの追加に失敗しました",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    const toggleCompletedComments = () => {
        setShowCompletedComments(!showCompletedComments);
    };

    const renderComments = () => {
        const uncompletedComments = comments.filter(comment => !comment.status);
        const completedComments = comments.filter(comment => comment.status);

        return (
            <VStack spacing={4} align="stretch" maxHeight="500px" overflowY="auto" pr={2}>
                {uncompletedComments.map(renderComment)}
                {completedComments.length > 0 && (
                    <Button
                        onClick={toggleCompletedComments}
                        width="100%"
                        bg="white"
                        border="1px"
                        borderColor="gray.200"
                        borderRadius="2xl"
                        p={3}
                        justifyContent="space-between"
                        _hover={{ bg: "gray.50" }}
                    >
                        <Text>完了したコメント ({completedComments.length})</Text>
                        {showCompletedComments ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    </Button>
                )}
                {showCompletedComments && completedComments.map(renderComment)}
            </VStack>
        );
    };

    const renderComment = (comment) => (
        <Box key={comment.id} bg="white" rounded="2xl" borderWidth="1px" borderColor="gray.200">
            <Box p={3}>
                <Flex align="center" mb={2}>
                    <Avatar size="sm" name={comment.author} src={`https://api.dicebear.com/6.x/initials/svg?seed=${comment.author}`} mr={2} />
                    <Text fontWeight="bold">{comment.author}</Text>
                    <Text fontSize="xs" color="gray.500" ml={2}>{formatDate(comment.timestamp)}</Text>
                </Flex>
                <Text fontSize="sm">{comment.content}</Text>
            </Box>
        </Box>
    );

    const formatDate = (dateString) => {
        console.log('Formatting date:', dateString);
        if (!dateString) return '締切未設定';
        let date;
        if (dateString instanceof Object && dateString.seconds) {
          // Firestoreのタイムスタンプオブジェクトの場合
          date = new Date(dateString.seconds * 1000);
        } else {
          date = new Date(dateString);
        }
        if (isNaN(date.getTime())) return '無効な日付';
        return date.toLocaleDateString('ja-JP', { year: 'numeric', month: 'long', day: 'numeric' });
    };
    
    const content = () => {
        return (
            <>
                
                <VStack spacing={8} align="stretch">
                    {esData && (
                        <Box bg="white" p={6} rounded="2xl" borderWidth="1px" borderColor="gray.200">
                            <Heading size="md" mb={2}>✨　添削対象ES　✨</Heading>
                            <Heading size="md" mb={4}>{esData.name}</Heading>
                            <Box>
                                <Text fontSize="sm" color="gray.500">👤ユーザーID</Text>
                                <Text fontWeight="semibold">{userId}</Text>
                            </Box>
                            <SimpleGrid columns={[1, null, 2]} spacing={4}>
                                <Box>
                                    <Text fontSize="sm" color="gray.500">👨🏻‍💼企業名</Text>
                                    <Text fontWeight="semibold">{esData.company}</Text>
                                </Box>
                                <Box>
                                    <Text fontSize="sm" color="gray.500">👨🏻‍💼職種名</Text>
                                    <Text fontWeight="semibold">{esData.jobType}</Text>
                                </Box>
                                <Box>
                                    <Text fontSize="sm" color="gray.500">⏳提出期限</Text>
                                    <Text fontWeight="semibold">{formatDate(esData.deadline)}</Text>
                                </Box>
                            </SimpleGrid>
                        </Box>
                    )}

                    {questionData && (
                        <Box bg="white" p={6} rounded="2xl" borderWidth="1px" borderColor="gray.200">
                            <Heading size="md" mb={4}>設問: {questionData.question}</Heading>
                            <Text fontSize="sm" color="gray.500" mb={2}>メモ {questionData.memo}</Text>
                            <Text whiteSpace="pre-wrap">{questionData.content}</Text>
                        </Box>
                    )}

                    <Box bg="white" p={6} rounded="2xl" borderWidth="1px" borderColor="gray.200">
                        <Heading size="md" mb={4}>添削コメント</Heading>
                        {renderComments()}
                        <Textarea
                            mt={4}
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            placeholder="コメントを入力してださい"
                            borderWidth={2}
                            borderColor="gray.200"
                            rounded="lg"
                            _focus={{ ring: 2, ringColor: "blue.500", borderColor: "transparent" }}
                        />
                        <Flex justify="flex-end" mt={2}>
                            <Button
                                borderRadius="full"
                                onClick={handleCommentSubmit}
                                bgGradient="linear(to-r, blue.400, purple.500)"
                                color="white"
                                _hover={{
                                    bgGradient: "linear(to-r, blue.500, purple.600)",
                                }}
                                _active={{
                                    bgGradient: "linear(to-r, blue.600, purple.700)",
                                }}
                            >
                                コメントを追加
                            </Button>
                        </Flex>
                    </Box>
                </VStack>

            </>
        )
    }

    return (
        <>
            
            <Box bgGradient="linear(to-r, purple.50, blue.50)" minH="100vh" zIndex="1" position="relative">
                <SinpleHeader isMobile={isMobile}/>    
                <Container  maxW="5xl" py={8}>
                    <VStack spacing={8} align="stretch">
                        <Box>
                            <Heading size="lg" mb={2}>📝　ES添削ページ</Heading>
                            <Text fontSize="sm" color="gray.500">ES添削</Text>
                        </Box>
                        {content()}
                    </VStack>
                </Container>
                <Box as="footer" bg="white" p={4} shadow="md" mt={8}>
                    <Text textAlign="center">&copy; { new Date().getFullYear()} ESDOCS</Text>
                </Box>
            </Box>
            
            
        </>
    );
};