import { Box, Flex, Spacer, Heading, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const SinpleHeader = ({isMobile, isReviewerPage = true , onClick, onLoginClick}) => {   
    const navigate = useNavigate();
  return (
    <Box borderBottom="1px" borderColor="gray.200" p='4' bg='white'>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex justifyContent="end" alignItems="end">
                  <Heading fontWeight='bold' onClick={() => navigate('/')} size={isMobile ? 'md' : 'lg'} bgGradient="linear(to-r, blue.500, purple.500)" bgClip="text">ES DOCS</Heading>
                  {isReviewerPage ||
           <Text ml={2} fontWeight='semibold' fontSize={isMobile ? 'sm' : 'md'} color='gray.500'>for 添削ページ</Text>   
                  }
        </Flex>
              <Spacer />
              {isReviewerPage &&
                <Button mr={2} variant='none' colorScheme='black'  fontWeight='semibold' fontSize={isMobile ? 'sm' : 'xl'} p={isMobile ? '2' : '6'} borderRadius="full"
                      onClick={() => onLoginClick()}  >
                  ログイン
                </Button>
              }
              <Button fontWeight='semibold' fontSize={isMobile ? 'sm' : 'xl'} p={isMobile ? '2' : '6'} bgGradient="linear(to-r, blue.500, purple.500)" color="white" _hover={{ bgGradient: "linear(to-r, blue.600, purple.600)" }} borderRadius="full"
                  onClick={isReviewerPage ? () => onClick() : () => navigate('/') }  >
                  {isReviewerPage ? '無料登録': '無料で利用開始'}
                  <Heading size={isMobile ? 'md' : 'xl'} color='gray.500'>🎉</Heading>
              </Button>
      </Flex>
    </Box>
  );
};

export default SinpleHeader;